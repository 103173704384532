import { Public } from '@iad-os/react-ghost-auth';
import { Route, Routes } from 'react-router';
import Clock from '../pages/Clock/Clock';

function PublicRouters() {
  return (
    <Public>
      <Routes>
        <Route path="/" element={<Clock />} />
      </Routes>
    </Public>
  );
}

export default PublicRouters;
