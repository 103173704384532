import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import omit from 'lodash.omit';

export type CircularProgressWithLabelType = CircularProgressProps & {
  value: number;
  max?: number;
  unit?: string;
  TextProps?: TypographyProps;
};

function CircularProgressWithLabel(props: CircularProgressWithLabelType) {
  const { value, max = 100, unit, TextProps = {} } = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...omit(props, ['value'])}
        value={(100 * value) / max}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography {...TextProps}>{`${Math.round(value)} ${unit}`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
