import { RequireAuth, useToken } from '@iad-os/react-ghost-auth';
import React from 'react';
import { Route, Routes } from 'react-router';
import Welcome from '../pages/Welcome/Welcome';

function ProtectedRouters() {
  try {
    const token = useToken();

    return token ? (
      <RequireAuth authRequired={true}>
        <Routes>
          <Route path="/" element={<Welcome />} />
        </Routes>
      </RequireAuth>
    ) : (
      <></>
    );
  } catch (err) {
    return (
      <RequireAuth authRequired={true}>
        <Routes>
          <Route path="/" element={<></>} />
        </Routes>
      </RequireAuth>
    );
  }
}

export default ProtectedRouters;
