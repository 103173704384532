import React, { useEffect, useState } from 'react';
import { useAuthentication, useToken } from '@iad-os/react-ghost-auth';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import CircularProgressWithLabel from '../../libs/components/CircularProgressWithLabel';
import { useOwlStatus } from '../../libs/contexts/OwlStatus';
import DefaultLayout from '../../libs/layouts/DefaultLayout';

const countdownSec = Number(process.env.REACT_APP_COUNTDOWN_SEC || 5);

function Welcome() {
  const { logout } = useAuthentication();

  const token = useToken();

  const { changeStatus } = useOwlStatus();

  const [countdown, setCountdown] = useState<number>(countdownSec);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    changeStatus('hallo');
    axios
      .post(
        '/api/doors',
        {},
        {
          headers: {
            authorization: `Bearer ${token.token.access_token}`,
          },
        }
      )
      .finally(() => {
        logout();
      });
    return () => {
      changeStatus('ok');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      logout();
      if (window.opener && !window.opener.closed) {
        window.close();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  return (
    <DefaultLayout>
      <Stack direction={'column'} alignItems="center" spacing={2}>
        <Typography variant="h3">BENVENUTO E BUON LAVORO</Typography>
        <CircularProgressWithLabel
          size={100}
          value={countdown}
          unit={'s'}
          max={countdownSec}
          color="success"
          TextProps={{
            variant: 'h3',
          }}
        />
      </Stack>
    </DefaultLayout>
  );
}

export default Welcome;
