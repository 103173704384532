import { Box, Grid } from '@mui/material';
import React from 'react';
import Owl from '../components/Owl';

export type DefaultLayoutProps = {
  children: React.ReactNode;
};

function DefaultLayout(props: DefaultLayoutProps) {
  const { children } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item sx={{ m: 4 }}>
        <Owl />
        <Box sx={{ backgroundColor: '#FFF', p: 4 }} borderRadius={5}>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
}

export default DefaultLayout;
