import AuthenticationProvider, {
  AuthenticationConfig,
  AutoLogin,
  LogginIn,
} from '@iad-os/react-ghost-auth';
import axios from 'axios';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import authConfig from './authConfig';
import ColorModeProvider from './libs/contexts/ColorMode';
import OwlStatusProvider from './libs/contexts/OwlStatus';

import ProtectedRoutes from './Routes/ProtectedRoutes';
import PublicRouters from './Routes/PublicRoutes';
import { useEffect, useMemo, useState } from 'react';

axios.defaults.baseURL =
  process.env.REACT_APP_SERVER_URL || window.location.origin;

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [checkNonce, setChceckNonce] = useState(false);

  const [nonceac, setNonceac] = useState('');

  const hasHttsSchema = useMemo(
    () => window.location.protocol === 'https:',
    []
  );
  useEffect(() => {
    if (hasHttsSchema) {
      const fetchData = async () => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const paramValue = queryParams.get('nonceac');
        if (paramValue) {
          localStorage.setItem('nonceac', paramValue);
          setNonceac(paramValue);
          const response = await axios.get(`/verify/nonce/?nonceac=${nonceac}`);
          setChceckNonce(
            window.location.hostname !== 'access-control' &&
              response.status === 200
          );
        } else {
          const paramValueStorage = localStorage.getItem('nonceac');
          if (paramValueStorage) {
            setNonceac(paramValueStorage);
            const response = await axios.get(
              `/verify/nonce/?nonceac=${nonceac}`
            );

            setChceckNonce(
              window.location.hostname !== 'access-control' &&
                response.status === 200
            );
          }
        }
        setIsLoading(false);
      };
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }
  }, [hasHttsSchema, nonceac]);

  console.log(
    `PRE USE EFFECT: hasHttsSchema: ${hasHttsSchema}, nonceac: ${nonceac}, checkNonce: ${checkNonce}`
  );
  /*useEffect(() => {
    if (hasHttsSchema && nonceac) {
      const fetchData = async () => {
        console.log('call verify nonce');
        const response = await axios.get(`/verify/nonce/?nonceac=${nonceac}`);
        setChceckNonce(response.status === 200);
      };

      fetchData().catch(console.error);
    }
  }, [hasHttsSchema, nonceac]);*/
  console.log(
    `POST USE EFFECT: hasHttsSchema: ${hasHttsSchema}, nonceac: ${nonceac}, checkNonce: ${checkNonce}`
  );

  const hasAccessControl = useMemo(
    () => window.location.hostname === 'access-control',
    []
  );

  const config = useMemo<AuthenticationConfig>(() => {
    return {
      providers: authConfig.providers.map(p => ({
        ...p,
        defualt: window.location.hostname.includes(p.name),
      })),
    };
  }, []);

  console.log(config);

  function handleRoute(route: string) {
    debugger;
    const url = new URL(route);
    console.log(url);
    navigate(url.pathname, { replace: true });
  }
  if (isLoading)
    return (
      <ColorModeProvider>
        <h2>🔄 CONTROLLO...</h2>
        <img
          src="/assets/logo-iad.png"
          height="80px"
          alt="logoiad"
          style={{
            position: 'fixed',
            bottom: '10px',
            right: '10px',
          }}
        />
      </ColorModeProvider>
    );

  return (
    <ColorModeProvider>
      {config && (
        <AuthenticationProvider
          config={config}
          onRoute={handleRoute}
          saveOnLocalStorage
        >
          <LogginIn>
            <h2>🔄 Loading...</h2>
          </LogginIn>
          <OwlStatusProvider>
            <Routes>
              {(hasAccessControl || checkNonce) && (
                <Route path="/protected/*" element={<ProtectedRoutes />} />
              )}
              {<Route path="/public/*" element={<PublicRouters />} />}

              <Route
                path="/"
                element={
                  <Navigate
                    to={`${
                      hasAccessControl || !checkNonce
                        ? `/public${window.location.search}`
                        : `/protected${window.location.search}`
                    }`}
                  />
                }
              />
              <Route path="/*" element={<h2>⚠ OOPS... Page not found! ⚠</h2>} />
            </Routes>
          </OwlStatusProvider>
          <AutoLogin />
        </AuthenticationProvider>
      )}

      <img
        src="/assets/logo-iad.png"
        height="80px"
        alt="logoiad"
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
        }}
      />
    </ColorModeProvider>
  );
}

export default App;
