import React, { useContext, useState } from 'react';

type CtxProps = {
  status?: 'ok' | 'fever' | 'no-watch' | 'hallo';
  changeStatus: (status: CtxProps['status']) => void;
  resourceUrl: string;
  setResourceUrl: (url: string) => void;
};

const OwlStatusContext = React.createContext({} as CtxProps);

export function useOwlStatus() {
  return useContext(OwlStatusContext);
}

function OwlStatusProvider(props: { children: React.ReactNode }) {
  const [status, setStatus] = useState<CtxProps['status']>('ok');

  const [resourceUrl, setResourceUrl] = useState<string>('');

  const handleChangeStatus = (status: CtxProps['status']) => {
    setStatus(status);
  };
  return (
    <OwlStatusContext.Provider
      value={{
        status,
        changeStatus: handleChangeStatus,
        resourceUrl,
        setResourceUrl,
      }}
    >
      {props.children}
    </OwlStatusContext.Provider>
  );
}

export default OwlStatusProvider;
