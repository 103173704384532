import { useAuthentication } from '@iad-os/react-ghost-auth';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const Clock = () => {
  const { login } = useAuthentication();

  const [time, setTime] = useState<{
    clock: string;
    date: string;
  }>();
  const refresh = 2 * 60 * 1000;
  const [imageUrl, setImageUrl] = useState('/qrmanager/qrcode.png');
  const [refreshToggle, setRefreshToggle] = useState(true);
  const [refreshTime, setRefreshTime] = useState(refresh); // Valore iniziale di 5 secondi
  const [countdown, setCountdown] = useState(refresh / 1000);
  useEffect(() => {
    const socket = io('/'); // Sostituisci con l'URL del tuo servizio Python

    socket.on('refresh_qrcode', () => {
      window.location.reload();
    });

    return () => {
      socket.disconnect();
    };
  }, [refreshTime, refreshToggle, imageUrl, refresh]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (refreshToggle) {
        setRefreshTime(50);
        setCountdown(0);
        setImageUrl('');
        setRefreshToggle(false);
      } else {
        setRefreshTime(refresh);
        setCountdown(refresh / 1000);
        setImageUrl('/qrmanager/qrcode.png');
      }
      setRefreshToggle(!refreshToggle);
    }, refreshTime);

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshToggle, refreshTime, refresh]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = moment();
      setTime(() => ({
        clock: `${now.format('HH:mm')}`,
        date: `${now.format('dddd DD MMMM')}`.toUpperCase(),
      }));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const countdownId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(countdownId);
    };
  }, [refreshTime]);

  return (
    <Stack
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ height: '100vh', background: 'black' }}
      onClick={() => login()}
    >
      <Typography variant="h1" color={'secondary'}>
        {time?.clock}
      </Typography>
      <Typography variant="h6" color={'secondary'}>
        {time?.date}
      </Typography>
      <p>Timer: {countdown}</p>
      {imageUrl && (
        <img
          key={refreshToggle ? 'refreshed' : 'not-refreshed'}
          src={imageUrl}
          alt="Descrizione dell'immagine"
        />
      )}
    </Stack>
  );
};

export default Clock;
