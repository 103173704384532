import React, { useMemo } from 'react';
import { useOwlStatus } from '../contexts/OwlStatus';

export type OwlProps = {};

function Owl(props: OwlProps) {
  const { status, resourceUrl } = useOwlStatus();

  const styles: { [key in string]: React.CSSProperties } = useMemo(() => {
    return {
      owl: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '211px',
        height: '100px',
        backgroundImage: `url("${resourceUrl}/assets/owl/${
          status === 'fever' ? 'owl-fever.png' : 'owl.png'
        }")`,
        position: 'relative',
      },
      owlHandLeft: {
        width: '34px',
        height: '34px',
        borderRadius: '40px',
        backgroundColor: '#472d20',
        transform:
          status === 'no-watch'
            ? 'translateX(42px) translateY(-15px) scale(0.7)'
            : 'scaleY(0.6)',
        position: 'absolute',
        left: '14px',
        bottom: '-8px',
        transition: '0.3s ease-out',
      },
      owlHandRight: {
        width: '34px',
        height: '34px',
        borderRadius: '40px',
        backgroundColor: '#472d20',
        transform: 'scaleY(0.6)',
        position: 'absolute',
        left: '170px',
        bottom: '-8px',
        transition: '0.3s ease-out',
        ...(status === 'no-watch' && {
          transform: 'translateX(-42px) translateY(-15px) scale(0.7)',
        }),
        ...(status === 'hallo' && { display: 'none' }),
      },
      arms: {
        position: 'absolute',
        top: '58px',
        height: '41px',
        width: '100%',
        overflow: 'hidden',
      },
      arm: {
        width: '40px',
        height: '65px',
        backgroundImage: `url("${resourceUrl}/assets/owl/owl-hide-eyes.png")`,
        position: 'absolute',
        left: '20px',
        top: '40px',
        transition: '0.3s ease-out',
        ...(status === 'no-watch' && {
          transform: 'translateX(40px) translateY(-40px)',
        }),
      },
      armRight: {
        left: '158px',
        transform: 'scaleX(-1)',
        ...(status === 'no-watch' && {
          transform: 'translateX(-40px) translateY(-40px) scaleX(-1)',
        }),
        ...(status === 'hallo' && {
          animationName: 'owlHallo',
          animationDuration: `${
            Number(process.env.REACT_APP_COUNTDOWN_SEC || '5') + 1
          }s`,
        }),
      },
    };
  }, [status, resourceUrl]);

  return (
    <div style={styles.owl}>
      <div id="hand-left" style={styles.owlHandLeft} />
      <div id="hand-right" style={styles.owlHandRight} />
      <div style={styles.arms}>
        <div id="arm-left" style={styles.arm} />
        <div id="arm-right" style={{ ...styles.arm, ...styles.armRight }} />
      </div>
    </div>
  );
}

export default Owl;
