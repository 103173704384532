import { AuthenticationConfig } from '@iad-os/react-ghost-auth';

export const PROVIDERS = {
  KEYCLOAK: 'keycloak',
  MS: 'ms',
  IAD2EU: 'iad2.eu',
};

const authConfig: AuthenticationConfig = {
  providers: [
    {
      issuer: PROVIDERS.KEYCLOAK,
      name: 'access-control',
      authorization_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM || ''
      }/protocol/openid-connect/auth`,
      token_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM || ''
      }/protocol/openid-connect/token`,
      client_id: process.env.REACT_APP_KC_CLIENT_ID || '',
      requested_scopes: 'openid',
      redirect_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_AUTH_REDIRECT || ''}`,
      end_session_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM || ''
      }/protocol/openid-connect/logout`,
      redirect_logout_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_LOGOUT_REDIRECT || ''}`,
      pkce: false,
    },
    {
      issuer: PROVIDERS.MS,
      name: '84-253-148-51.ngrok-free.app',
      authorization_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/auth`,
      token_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/token`,
      client_id: process.env.REACT_APP_KC_CLIENT_ID_MS || '',
      requested_scopes: 'openid',
      redirect_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_AUTH_REDIRECT || ''}`,
      end_session_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/logout`,
      redirect_logout_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_LOGOUT_REDIRECT || ''}`,
      pkce: true,
      kc_idp_hint: 'streethole',
    },
    {
      issuer: PROVIDERS.IAD2EU,
      name: 'colombo-ac.iad2.eu',
      authorization_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/auth`,
      token_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/token`,
      client_id: process.env.REACT_APP_KC_CLIENT_ID_MS || '',
      requested_scopes: 'openid',
      redirect_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_AUTH_REDIRECT || ''}`,
      end_session_endpoint: `${
        process.env.REACT_APP_KEYCLOAK_BASE_URL_MS || window.location.origin
      }/auth/realms/${
        process.env.REACT_APP_KEYCLOAK_APP_REALM_MS || ''
      }/protocol/openid-connect/logout`,
      redirect_logout_uri: `${
        process.env.REACT_APP_SERVER_URL || window.location.origin
      }${process.env.REACT_APP_KC_LOGOUT_REDIRECT || ''}`,
      pkce: true,
      kc_idp_hint: 'streethole',
    },
  ],
};
/*
authConfig.providers[PROVIDERS.KEYCLOAK] = {
  name: PROVIDERS.KEYCLOAK,
  authorization_endpoint: `${
    process.env.REACT_APP_KC_URL || ''}/protocol/openid-connect/auth`,
  token_endpoint: `${
    process.env.REACT_APP_KC_URL || ''
  }/protocol/openid-connect/token`,
  client_id: process.env.REACT_APP_KC_CLIENT_ID || '',
  requested_scopes: 'openid',
  redirect_uri: process.env.REACT_APP_KC_AUTH_REDIRECT || '',
  end_session_endpoint: `${
    process.env.REACT_APP_KC_URL || ''
  }/protocol/openid-connect/logout`,
  redirect_logout_uri: process.env.REACT_APP_KC_LOGOUT_REDIRECT || '',
  pkce: false,
};
*/

export default authConfig;
